import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "byte-sizes"
    }}>{`Byte Sizes`}</h1>
    <p>{`Use the following format for numeric abbreviations:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`1 KB (kilobytes)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`1 MB (megabytes)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`1 GB (gigabytes)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`4 TB (terabytes)`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      